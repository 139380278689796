/* tslint:disable */
/* eslint-disable */
/**
 * Koralm-Web-API
 * All UnitsOfMeasures are in <a href=\"https://en.wikipedia.org/wiki/International_System_of_Units\">SI base units</a>, unless otherwise described. The <code>siValue</code> property of those objects exists just for json serialisation purposes. All server side operations such as sorting and filtering operations may be performed on the property directly, not on its nested <code>siValue</code> property. <br>The Pagination index is zero-based. If a page is empty its index will be <code>-1</code>. Sorting and filter properties of nested objects are case-sensitive and seperated by the <code>.</code>-character as a delimiter.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { GroupData } from '../model';
// @ts-ignore
import { UserOutput } from '../model';
/**
 * AdminGroupDataApi - axios parameter creator
 * @export
 */
export const AdminGroupDataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGroupDataGetGroupMembers: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/AdminGroupData/GetGroupMembers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGroupDataGetGroups: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/AdminGroupData/GetGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminGroupDataApi - functional programming interface
 * @export
 */
export const AdminGroupDataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminGroupDataApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGroupDataGetGroupMembers(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserOutput>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGroupDataGetGroupMembers(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async adminGroupDataGetGroupMembersSendRequest(id?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<UserOutput>>> {
            return (await this.adminGroupDataGetGroupMembers(id,  options))();
        },
        async adminGroupDataGetGroupMembersAndGetData(id?: string, options?: AxiosRequestConfig): Promise<Array<UserOutput>> {
            return (await this.adminGroupDataGetGroupMembersSendRequest(id,  options)).data;
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGroupDataGetGroups(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GroupData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGroupDataGetGroups(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async adminGroupDataGetGroupsSendRequest(options?: AxiosRequestConfig): Promise<AxiosResponse<Array<GroupData>>> {
            return (await this.adminGroupDataGetGroups( options))();
        },
        async adminGroupDataGetGroupsAndGetData(options?: AxiosRequestConfig): Promise<Array<GroupData>> {
            return (await this.adminGroupDataGetGroupsSendRequest( options)).data;
        },
    }
};

/**
 * AdminGroupDataApi - factory interface
 * @export
 */
export const AdminGroupDataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminGroupDataApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGroupDataGetGroupMembers(id?: string, options?: any): AxiosPromise<Array<UserOutput>> {
            return localVarFp.adminGroupDataGetGroupMembers(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGroupDataGetGroups(options?: any): AxiosPromise<Array<GroupData>> {
            return localVarFp.adminGroupDataGetGroups(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminGroupDataApi - interface
 * @export
 * @interface AdminGroupDataApi
 */
export interface AdminGroupDataApiInterface {
    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminGroupDataApiInterface
     */
    adminGroupDataGetGroupMembers(id?: string, options?: AxiosRequestConfig): AxiosPromise<Array<UserOutput>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminGroupDataApiInterface
     */
    adminGroupDataGetGroups(options?: AxiosRequestConfig): AxiosPromise<Array<GroupData>>;

}

/**
 * AdminGroupDataApi - object-oriented interface
 * @export
 * @class AdminGroupDataApi
 * @extends {BaseAPI}
 */
export class AdminGroupDataApi extends BaseAPI implements AdminGroupDataApiInterface {
    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminGroupDataApi
     */
    public adminGroupDataGetGroupMembers(id?: string, options?: AxiosRequestConfig) {
        return AdminGroupDataApiFp(this.configuration).adminGroupDataGetGroupMembers(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminGroupDataApi
     */
    public adminGroupDataGetGroups(options?: AxiosRequestConfig) {
        return AdminGroupDataApiFp(this.configuration).adminGroupDataGetGroups(options).then((request) => request(this.axios, this.basePath));
    }
}
