import {Box, Stack} from "@mui/material";
import DefaultDataGrid from "../DefaultDataGrid/DefaultDataGrid";
import React, {useCallback, useEffect, useState} from "react";
import {GridRenderCellParams, GridRowSpacingParams} from "@mui/x-data-grid";
import {AdminUserDataApiFp, GroupData, UserOutput} from "../../generated";
import {UserCreationPopupButton} from "./UserCreationPopup";
import {GridValueGetterParams} from "@mui/x-data-grid/models/params/gridCellParams";
import {UserEditPopupButton} from "./UserEditingPopup";


interface UserListProps {
    availableGroups: GroupData[]
}

export interface UserData {
    ID: string;
    Output: UserOutput;
}

function UserList({availableGroups}: UserListProps) {

    const columns = [
        {field: "ID", headerName: "ID", hide: true, filterable: false, sortable: false},
        {
            field: 'edit',
            headerName: '',
            width: 80,
            renderCell: (params: GridRenderCellParams) => (
                <UserEditPopupButton
                    editingUserId={editingUserId}
                    setEditingUser={onEditOpen}
                    availableGroups={availableGroups}
                    user={(params.row as UserData).Output}
                    allUsers={userData}
                />
            ),
        },
        {
            field: "Username", headerName: "Nutzername", minWidth: 200,
            valueGetter: (params: GridValueGetterParams) => params.row.Output.Data.Username
        },
        {
            field: "Firstname", headerName: "Vorname", minWidth: 200,
            valueGetter: (params: GridValueGetterParams) => params.row.Output.Data.FirstName
        },
        {
            field: "Lastname", headerName: "Nachname", minWidth: 200,
            valueGetter: (params: GridValueGetterParams) => params.row.Output.Data.LastName
        },
        {
            field: "Email", headerName: "Email", minWidth: 300,
            valueGetter: (params: GridValueGetterParams) => params.row.Output.Data.Email
        },
        {
            field: "Companies", headerName: "Firma", minWidth: 300, sortable: false,
            valueGetter: (params: GridValueGetterParams) => params.row.Output.Data.Companies
        },

    ]

    const [userData, setUserData] = useState<UserData[]>([]);
    const [isCreateOpen, setCreateOpen] = useState<boolean>(false);
    const [editingUserId, setEditingUserId] = useState<string | null>(null);


    const fetchUsers = useCallback(() =>
        AdminUserDataApiFp()
            .adminUserDataUsersAndGetData()
            .then(data => setUserData(data.map(user => ({ID: user.Id, Output: user}))))
            .catch(error => console.error("Error fetching users:", error)), []);


    useEffect(() => {
        void fetchUsers();
    }, [fetchUsers]);

    const onEditOpen = (open: string | null) => {
        setEditingUserId(open)
        if (open == null) void fetchUsers();
    }

    const onCreateOpen = (open: boolean) => {
        setCreateOpen(open)
        if (!open) void fetchUsers();
    }

    const getRowSpacing = React.useCallback((params: GridRowSpacingParams) => {
        return {
            top: params.isFirstVisible ? 0 : 5,
            bottom: params.isLastVisible ? 0 : 5,
        };
    }, []);


    return <Stack spacing={2} sx={{display: 'flex', flexGrow: 1}}>
        <UserCreationPopupButton availableGroups={availableGroups} allUsers={userData} isOpen={isCreateOpen} setOpen={onCreateOpen}/>
        <Box sx={{height: '100%', width: '100%', display: 'flex', flexGrow: 1}}>
            <DefaultDataGrid mode="client" rows={userData} columns={columns} getRowSpacing={getRowSpacing}/>
        </Box>
    </Stack>
}


export default UserList