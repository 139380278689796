import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Theme} from "@mui/material";
import React from "react";
import {GroupData} from "../../generated";
import {contractorService} from "../../services/contractorProvider";

interface RoleDropdownParams {
    selectedGroup: GroupData | null;
    availableGroups: GroupData[];
    theme: Theme;
    errorFields: any;
    setSelectedGroup: (selected: GroupData | null) => void
}

export function RoleDropdown({
                                 errorFields,
                                 selectedGroup,
                                 theme,
                                 availableGroups,
                                 setSelectedGroup
                             }: RoleDropdownParams) {
    return <FormControl fullWidth margin="normal" required error={errorFields.role}>
        <InputLabel id="role-label">Rolle</InputLabel>
        <Select
            labelId="role-label"
            label="Rolle"
            value={selectedGroup?.Name || ''} // Assuming you want to show the first group's name
            onChange={(e) => {
                const selectedGroup = availableGroups.find((grp) => grp.Name === e.target.value);
                setSelectedGroup(selectedGroup ? selectedGroup : null);
            }}
        >
            {availableGroups.map((group) => (
                <MenuItem key={group.Id} value={group.Name}>{group.Name}</MenuItem>
            ))}
        </Select>
        {errorFields.role &&
            <span style={{color: theme.palette.error.main}}>Rolle ist erforderlich</span>}
    </FormControl>
}

interface ContractorDropdownParams {
    selectedContractor: string[];
    setSelectedContractor: (selected: string[]) => void

}

export function ContractorDropdown({
                                       selectedContractor,
                                       setSelectedContractor
                                   }: ContractorDropdownParams) {
    return <FormControl fullWidth margin="normal" >
        <InputLabel id="company-label">Firma</InputLabel>
        <Select
            multiple
            labelId="company-label"
            label="Firma"
            value={selectedContractor}
            onChange={(event: SelectChangeEvent<typeof selectedContractor>) =>
                setSelectedContractor(typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value)}
        >
            {contractorService.contractors.map((contractor) => (
                <MenuItem key={contractor.ID} value={contractor.DisplayName}>{contractor.DisplayName}</MenuItem>
            ))}
        </Select>
    </FormControl>
}