import {IconButton, LinearProgress, Stack, Tooltip, Typography} from "@mui/material";
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import React, {useEffect, useState} from "react";
import {WebAppPage} from "../../utils/Types";
import HeaderBar from "../HeaderBar/HeaderBar";
import RolesList from "./RolesList";
import UserList from "./UserList";
import {AdminGroupDataApiFp, GroupData} from "../../generated";

interface UserManagementButtonProps {
    changedSelectedPage: (value: WebAppPage) => void;
}

function UserManagementButton(props: UserManagementButtonProps) {
    return <Tooltip title={<Typography>User anlegen / verwalten</Typography>}>
        <IconButton
            size="large"
            sx={{color: "#ffffff"}}
            onClick={() => props.changedSelectedPage(WebAppPage.UserManagement)}
        >
            <GroupAddOutlinedIcon fontSize="large"/>
        </IconButton>
    </Tooltip>
}


interface UserManagementPageProps {
    changedSelectedPage: (value: WebAppPage) => void;
}

function UserManagementPage(props: UserManagementPageProps) {
    const [availableGroups, setAvailableGroups] = useState<GroupData[]>([]);

    useEffect(() => {
        AdminGroupDataApiFp()
            .adminGroupDataGetGroupsAndGetData()
            .then(setAvailableGroups)
            .catch(e => console.log("Error fetching groups:", e));
    }, []);

    return <Stack>
        <HeaderBar
            changedSelectedWorkRegistration={() => {
            }}
            isInDataTable={false}
            selectedPage={WebAppPage.UserManagement}
            changedSelectedPage={props.changedSelectedPage}
            isNewWorkRegistration={false}
            newNotification={false}
        />
        {availableGroups.length === 0 ?
            <LinearProgress/> :
            <Stack
                direction="row"
                spacing={10}
                sx={{height: '90vh', width: '94vw', display: 'flex', flexDirection: 'row', flexGrow: 1, padding: 3}}
            >
                <RolesList availableGroups={availableGroups}/>
                <UserList availableGroups={availableGroups}/>
            </Stack>
        }
    </Stack>
}

export {UserManagementPage, UserManagementButton};
