import React from 'react';
import {Grid, IconButton, Stack, ToggleButton, ToggleButtonGroup, Tooltip, Typography} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {LOG_IN_EVENT_FLAG} from "../../constants";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ListIcon from '@mui/icons-material/List';
import {WebAppPage} from "../../utils/Types";
import UserService from "../../services/UserService";
import {TopBarArgs} from "../../utils/InterfaceProps";
import {HeaderBarDefault, HeaderBarNewRegistration} from "../../utils/Colors";
import RejectedWorkRegistationsPopup from "./RejectedWorkRegistationsPopup";
import MaterialLogisticsPopUp from "./MaterialLogisticsPopUp";
import UnfinishedWorkRegistrations from "./UnfinishedWorkRegistrations";
import ThirdLevelOutlineButton from "./ThirdLevelOutlineButton";
import ContractorEditor from "./ContractorEditor";
import {PermissionManager} from "../../utils/PermissionManager";
import {UserManagementButton} from "../UserManagementPage/UserManagementPage";

function HeaderBar(props: TopBarArgs) {
    const shouldOpenOnLogin = localStorage.getItem(LOG_IN_EVENT_FLAG) === "true";

    const HeaderColor = props.isNewWorkRegistration ? HeaderBarNewRegistration : HeaderBarDefault;

    function handleLockout() {
        UserService.doLogout({redirectUri: window.location.origin})
    }

    const handleSelectedPageChange = (event: React.MouseEvent<HTMLElement, MouseEvent>, newPage: WebAppPage) => {
        if (newPage !== null) props.changedSelectedPage(newPage)
    }

    return (
        <Grid
            sx={{
                backgroundColor: HeaderColor,
                justifyContent: "center",
                alignItems: "center",
                gridRow: 1,
                gridColumn: 1 / -1
            }}
            container
            alignItems={"center"}
            padding={2}
            spacing={2}
        >
            <Grid
                item
                xs={6}
                style={{ display: "flex", justifyContent: 'flex-start', alignItems: "flex-start" }}
            >
                <>
                    {props.isInDataTable ? (
                        <Stack direction="row" spacing={2}>
                            <ToggleButtonGroup
                                color="standard"
                                value={props.selectedPage}
                                exclusive
                                onChange={handleSelectedPageChange}
                                aria-label="HeaderBarToggle"
                                sx={{backgroundColor: "#ffffff"}}
                            >
                                <Tooltip title={<Typography>Listenansicht</Typography>}>
                                    <ToggleButton value={WebAppPage.ListView} sx={{width: 100}}>
                                        <ListIcon/>
                                    </ToggleButton>
                                </Tooltip>
                                <Tooltip title={<Typography>Wochenansicht</Typography>}>
                                    <ToggleButton value={WebAppPage.WeekView} sx={{width: 100}}>
                                        <CalendarMonthIcon/>
                                    </ToggleButton>
                                </Tooltip>
                            </ToggleButtonGroup>
                            {PermissionManager.Universal.showRejectedWorkRegistationsPopup && (
                                <RejectedWorkRegistationsPopup
                                    changedSelectedWorkRegistration={props.changedSelectedWorkRegistration}
                                    newNotification={props.newNotification}
                                    shouldOpenOnLogin={shouldOpenOnLogin}
                                />
                            )}
                            {PermissionManager.Universal.showUnfinishedWorkRegistrationsDialog && (
                                <UnfinishedWorkRegistrations
                                    changedSelectedWorkRegistration={props.changedSelectedWorkRegistration}
                                />
                            )}
                            {PermissionManager.Universal.showMaterialLogisticsPopUp && <MaterialLogisticsPopUp/>}
                        </Stack>
                    ) : props.selectedPage === WebAppPage.WorkRegistration ? (
                        <Stack direction="row" spacing={2}>
                            <IconButton
                                sx={{color: "#ffffff"}}
                                onClick={() => props.changedSelectedPage(WebAppPage.ListView)}
                            >
                                <ArrowBackIcon fontSize="large"/>
                            </IconButton>
                            <Typography variant="h4" color="white" paddingTop={0.5}>
                                Arbeitsanmeldung
                            </Typography>
                        </Stack>
                    ) : props.selectedPage === WebAppPage.UserManagement ? (
                        <Stack direction="row" spacing={2}>
                            <IconButton
                                sx={{color: "#ffffff"}}
                                onClick={() => props.changedSelectedPage(WebAppPage.ListView)}
                            >
                                <ArrowBackIcon fontSize="large"/>
                            </IconButton>
                            <Typography variant="h4" color="white" paddingTop={0.5}>
                                User Management
                            </Typography>
                        </Stack>
                    ) : null}
                </>
            </Grid>
            <Grid
                item
                xs={6}
                style={{display: "flex", justifyContent: 'flex-end', alignItems: "flex-end"}}
            >
                <Stack direction="row-reverse" spacing={2}>
                    <Tooltip title={<Typography>Abmelden</Typography>}>
                        <IconButton size="large" sx={{color: "#ffffff"}} onClick={handleLockout}> <LogoutIcon
                            fontSize="large"/> </IconButton>
                    </Tooltip>
                    <Typography
                        variant="h4"
                        color="white"
                        paddingTop={1}>
                        {UserService.getUsername()}
                    </Typography>
                    {props.isInDataTable && PermissionManager.Universal.showThirdLevelOutlineEditor &&
                        <ThirdLevelOutlineButton/>
                    }
                    {props.selectedPage != WebAppPage.WorkRegistration && PermissionManager.Universal.showContractorEditor &&
                        <ContractorEditor/>
                    }
                    {props.selectedPage != WebAppPage.WorkRegistration && PermissionManager.Universal.showContractorEditor &&
                        <UserManagementButton changedSelectedPage={props.changedSelectedPage}/>
                    }
                    {props.isInDataTable && (PermissionManager.Universal.showThirdLevelOutlineEditor || PermissionManager.Universal.showContractorEditor) &&
                        <div style={{width: 20}}/>
                    }
                </Stack>
            </Grid>
        </Grid>
    )

}

export default HeaderBar;