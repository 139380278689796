import React from 'react';
import {
    Box,
    FormControl,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Slider,
    Stack,
    ToggleButtonGroup,
    Typography,
} from '@mui/material';
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import {LengthSelectorArgs} from "../../utils/InterfaceProps";
import {
    KoralmLocation,
    LatitudinalDirection,
    RailNumber,
    RailTrackStatus,
    Tube,
    TunnelObject,
    WorkDirection,
} from "../../generated";
import {HeaderBarNewRegistration, railTrackStatusToColors, SELECTED_MENU_ITEM} from "../../utils/Colors";
import {LocalizationService} from "../../utils/Localization";
import {PermissionManager} from "../../utils/PermissionManager";
import {ObjectType, OutdoorBuildingTypes} from "../../utils/Types";
import {
    CrossCutMenuItems,
    EmergencyStopMenuItems,
    EscapeGalleryMenuItems,
    LatitudinalDirectionMenuItems,
    OutdoorBuildingMenuItems,
    ZoneMenuItems
} from "../../utils/Exports";
import {VENTILATIONSHAFT_BUILDING, VENTILATIONSHAFT_SHAFT} from "../../utils/FilterTypes";
import {styled} from "@mui/material/styles";
import MuiToggleButton from "@mui/material/ToggleButton";
import {tunnelConstructionService} from "../../services/tunnelConstructionsDescriptionsProvider";
import {railDescriptionService} from "../../services/railDescriptionsProvider";

function LengthSelectors(props: LengthSelectorArgs) {
    const menuItem1 = <MenuItem value={Tube.North} key="menuItem1">{LocalizationService.Tube(Tube.North)}</MenuItem>
    const menuItem2 = <MenuItem value={Tube.South} key="menuItem2">{LocalizationService.Tube(Tube.South)}</MenuItem>
    const menuItem3 = <MenuItem value={TunnelObject.CrossCut}
                                key="menuItem3">{LocalizationService.TunnelObject(TunnelObject.CrossCut)}</MenuItem>
    const menuItem4 = <MenuItem value={RailNumber.Eins}
                                key="menuItem4">{LocalizationService.RailNumberWithLongitudinalDirection(RailNumber.Eins)}</MenuItem>
    const menuItem5 = <MenuItem value={RailNumber.Zwei}
                                key="menuItem5">{LocalizationService.RailNumberWithLongitudinalDirection(RailNumber.Zwei)}</MenuItem>
    const menuItem6 = <MenuItem value={TunnelObject.EscapeGallery}
                                key="menuItem6">{LocalizationService.TunnelObject(TunnelObject.EscapeGallery)}</MenuItem>
    const menuItem7 = <MenuItem value={TunnelObject.Portal}
                                key="menuItem7">{LocalizationService.TunnelObject(TunnelObject.Portal)}</MenuItem>
    const menuItem8 = <MenuItem value={TunnelObject.VentilationShaft}
                                key="menuItem8">{LocalizationService.TunnelObject(TunnelObject.VentilationShaft)}</MenuItem>
    const menuItem9 = <MenuItem value={TunnelObject.EmergencyStop}
                                key="menuItem9">{LocalizationService.TunnelObject(TunnelObject.EmergencyStop)}</MenuItem>

    const listOfTunnelElements: JSX.Element[] = [menuItem1, menuItem2, menuItem3, menuItem6, menuItem7, menuItem8, menuItem9];
    const listOfOpenLineElements: JSX.Element[] = [menuItem4, menuItem5]
    const [menuOptions, setMenuOptions] = React.useState(props.locationType === KoralmLocation.OpenLine ? listOfOpenLineElements : listOfTunnelElements)
    const [buttonIconToUse, setButtonIconToUse] = React.useState(props.isGoingWest === WorkDirection.West ?
        <WestIcon/> : <EastIcon/>);

    const midpointOfRailSection = Math.round(((railDescriptionService.railSectionDescriptions[0].RailAreaDescription.Start.siValue + railDescriptionService.railSectionDescriptions[0].RailAreaDescription.End.siValue) / 2) / 100) * 100;
    const selectComponentPaddings = 2;

    const ToggleButton = styled(MuiToggleButton)({
        "&.Mui-selected, &.Mui-selected:hover": {
            color: "white",
            backgroundColor: HeaderBarNewRegistration,
        }
    });

    const meterToKilometer = (value: number) => value / 1000;
    const kilometerToMeter = (value: number) => value * 1000;

    const flipSides = props.latitudinalDirectionConstruction === LatitudinalDirection.East;
    const currentRail: number = props.selectedObjectType === RailNumber.Eins ? flipSides ? 0 : 1 : flipSides ? 2 : 3;
    const minValue = Number(meterToKilometer(railDescriptionService.railSectionDescriptions[currentRail].RailAreaDescription.End.siValue).toFixed(1));
    const maxValue = Number(meterToKilometer(railDescriptionService.railSectionDescriptions[currentRail].RailAreaDescription.Start.siValue).toFixed(1));

    function handleFirstLevelTunnelObjectInputs(object?: ObjectType) {
        switch (object) {
            case TunnelObject.CrossCut:
                return CrossCutMenuItems();
            case Tube.South:
            case Tube.North:
                return ZoneMenuItems(false)
            case TunnelObject.VentilationShaft:
            case TunnelObject.Portal:
                return LatitudinalDirectionMenuItems();
            case TunnelObject.EscapeGallery:
                return EscapeGalleryMenuItems();
            case TunnelObject.EmergencyStop:
                return EmergencyStopMenuItems();
        }
    }

    const handleSliderChange = (event: Event, newValue: number | number[]) => {
        const value: number[] = newValue as number[];
        props.changedUnitInputFieldLeft(kilometerToMeter(value[0]).toString())
        props.changedUnitInputFieldRight(kilometerToMeter(value[1]).toString())
    };

    const handleSliderInputChange = (value: number, left: boolean) => {
        const clampedValue = Math.max(minValue, Math.min(value, maxValue));
        return left ? props.changedUnitInputFieldLeft(kilometerToMeter(clampedValue).toString()) : props.changedUnitInputFieldRight(kilometerToMeter(clampedValue).toString())
    }

    const handleKoralmLocationToggle = (event: React.MouseEvent<HTMLElement>, newLocation: KoralmLocation | null,) => {
        if (newLocation !== null) {
            props.changedLocationType(newLocation)
            if (newLocation === KoralmLocation.Tunnel) {
                setMenuOptions([menuItem1, menuItem2, menuItem3, menuItem6, menuItem7, menuItem8, menuItem9])
                props.changedSelectedObjectType(Tube.North)
                props.changedUnitInputFieldRight(tunnelConstructionService.crossCutDescriptions[0].ID.toString())
                props.changedUnitInputFieldLeft(tunnelConstructionService.crossCutDescriptions[0].ID.toString())
            } else {
                setMenuOptions([menuItem4, menuItem5])
                props.changedSelectedObjectType(RailNumber.Eins)
                props.changedUnitInputFieldRight((midpointOfRailSection + 1000).toString())
                props.changedUnitInputFieldLeft((midpointOfRailSection - 1000).toString())
            }
        }
    };
    const handleObjectTypeSelection = (event: SelectChangeEvent) => {
        let valueBefore = props.selectedObjectType
        if (Object.values(RailNumber).includes(event.target.value as RailNumber)) {
            props.changedSelectedObjectType(event.target.value as RailNumber);
        } else if (Object.values(TunnelObject).includes(event.target.value as TunnelObject)) {
            props.changedSelectedObjectType(event.target.value as TunnelObject);
        } else {
            props.changedSelectedObjectType(event.target.value as Tube);
        }
        switch (event.target.value as ObjectType) {
            case Tube.North:
            case Tube.South:
            case TunnelObject.CrossCut:
                if (valueBefore !== Tube.North && valueBefore !== Tube.South && valueBefore !== TunnelObject.CrossCut && valueBefore !== TunnelObject.EscapeGallery) {
                    props.changedUnitInputFieldLeft(tunnelConstructionService.crossCutDescriptions[0].ID.toString())
                    props.changedUnitInputFieldRight(tunnelConstructionService.crossCutDescriptions[0].ID.toString())
                }
                break;
            case TunnelObject.EscapeGallery:
                if (valueBefore === Tube.North || valueBefore === Tube.South || valueBefore === TunnelObject.CrossCut) {
                    if (parseInt(props.unitInputFieldFirstLevel) > tunnelConstructionService.escapeGalleriesDescriptions[tunnelConstructionService.escapeGalleriesDescriptions.length - 1].ID) {
                        props.changedUnitInputFieldRight(tunnelConstructionService.escapeGalleriesDescriptions[tunnelConstructionService.escapeGalleriesDescriptions.length - 1].ID.toString())
                    }
                } else {
                    props.changedUnitInputFieldRight(tunnelConstructionService.escapeGalleriesDescriptions[0].ID.toString())
                }
                break;
            case TunnelObject.Portal:
            case TunnelObject.VentilationShaft:
                if (valueBefore !== TunnelObject.Portal && valueBefore !== TunnelObject.VentilationShaft) {
                    props.changedUnitInputFieldRight(LatitudinalDirection.East)
                }
                break;
            case RailNumber.Eins:
            case RailNumber.Zwei:
                if (valueBefore !== RailNumber.Eins && valueBefore !== RailNumber.Zwei) {
                    props.changedUnitInputFieldLeft((midpointOfRailSection + 1000).toString())
                    props.changedUnitInputFieldRight((midpointOfRailSection - 1000).toString())
                }
                break;
            case TunnelObject.EmergencyStop:
                props.changedUnitInputFieldRight(tunnelConstructionService.emergencyStopDescription[0].ID as unknown as string)
                break;
        }
    };
    const handleTravelDirectionToggle = () => {
        if (props.isGoingWest === WorkDirection.West) {
            props.changedIsGoingWest(WorkDirection.East)
            setButtonIconToUse(<EastIcon/>)
        } else {
            props.changedIsGoingWest(WorkDirection.West)
            setButtonIconToUse(<WestIcon/>)
        }
    }

    function renderFirstLevelInputField(location: KoralmLocation, object?: ObjectType) {
        if (location !== KoralmLocation.OpenLine) {
            return (
                <FormControl>
                    <InputLabel
                        id="FirstLevelSelection">{Object.hasOwn(Tube, props.selectedObjectType) ? "Bis" : ""}</InputLabel>
                    <Select
                        disabled={props.isNewWorkRegistration ? !PermissionManager.NewWorkRegistration.areaTo : !PermissionManager.WorkRegistrationEditing.areaTo}
                        name="Tube Selection End"
                        onChange={(event) => props.changedUnitInputFieldRight(event.target.value as string)}
                        value={props.unitInputFieldFirstLevel}
                        sx={{width: 200}}
                        MenuProps={{
                            sx: {
                                "&& .Mui-selected": {
                                    backgroundColor: SELECTED_MENU_ITEM
                                }
                            }
                        }}
                        id="FirstLevelSelection"
                        label={Object.hasOwn(Tube, props.selectedObjectType) ? "Bis" : ""}
                    >
                        {handleFirstLevelTunnelObjectInputs(object)}
                    </Select>
                </FormControl>
            )
        } else {
            return <div/>;
        }
    }

    function renderSecondLevelInputField(location: KoralmLocation, object?: ObjectType) {
        switch (object) {
            case TunnelObject.CrossCut:
            case TunnelObject.Tube:
            case TunnelObject.EscapeGallery:
            case TunnelObject.Portal:
            case TunnelObject.EmergencyStop:
                return <Stack/>
            case TunnelObject.VentilationShaft:
                return (
                    <Select
                        onChange={(event) => props.changedIsOnOuterConstruction(event.target.value as boolean)}
                        value={props.isOnOuterConstruction}
                        sx={{width: 200, height: 55}}
                        MenuProps={{
                            sx: {
                                "&& .Mui-selected": {
                                    backgroundColor: SELECTED_MENU_ITEM
                                }
                            }
                        }}
                    >
                        <MenuItem value={false as any}>{VENTILATIONSHAFT_SHAFT}</MenuItem>
                        <MenuItem value={true as any}>{VENTILATIONSHAFT_BUILDING}</MenuItem>
                    </Select>
                );
            default:
                switch (location) {
                    case KoralmLocation.Tunnel:
                        return (
                            <FormControl>
                                <InputLabel id="LocationSelectionLabel">Von</InputLabel>
                                <Select
                                    disabled={props.isNewWorkRegistration ? !PermissionManager.NewWorkRegistration.areaFrom : !PermissionManager.WorkRegistrationEditing.areaFrom}
                                    name="Tube Selection Start"
                                    onChange={(event) => props.changedUnitInputFieldLeft(event.target.value as string)}
                                    value={props.unitInputFieldSecondLevel}
                                    sx={{width: 200}}
                                    MenuProps={{
                                        sx: {
                                            "&& .Mui-selected": {
                                                backgroundColor: SELECTED_MENU_ITEM
                                            }
                                        }
                                    }}
                                    id="LocationSelectionLabel"
                                    label="Von"
                                >
                                    {ZoneMenuItems(true)}
                                </Select>
                            </FormControl>
                        );
                    default:
                        return <Stack/>
                }
        }
    }

    const renderOpenLineValueInputs = () => {
        return (
            <Stack>
                <Stack direction="row" justifyContent="space-between">
                    <Typography>von Kilometer:</Typography>
                    <Typography>bis Kilometer:</Typography>
                </Stack>
                <Stack direction="row">
                    <Input
                        disabled={props.isNewWorkRegistration ? !PermissionManager.NewWorkRegistration.areaTo : !PermissionManager.WorkRegistrationEditing.areaTo}
                        value={meterToKilometer(Number(props.unitInputFieldSecondLevel))}
                        size="medium"
                        onChange={(event) => handleSliderInputChange(Number(event.target.value), true)}
                        inputProps={{
                            step: 0.100,
                            min: minValue <= maxValue ? minValue : maxValue,
                            max: minValue <= maxValue ? maxValue : minValue,
                            type: 'number',
                            'aria-labelledby': 'input-slider-left',
                        }}
                        startAdornment={<InputAdornment position="start">km</InputAdornment>}
                        sx={{marginRight: 2, width: 165, height: 30}}
                    />
                    <Slider
                        disabled={props.isNewWorkRegistration ? !PermissionManager.NewWorkRegistration.areaTo : !PermissionManager.WorkRegistrationEditing.areaTo}
                        getAriaLabel={() => 'Outdoor Slider'}
                        value={[meterToKilometer(Number(props.unitInputFieldSecondLevel)), meterToKilometer(Number(props.unitInputFieldFirstLevel))]}
                        onChange={handleSliderChange}
                        valueLabelDisplay="auto"
                        color="primary"
                        min={minValue <= maxValue ? minValue : maxValue}
                        max={minValue <= maxValue ? maxValue : minValue}
                        step={0.100}
                        sx={{
                            '& .MuiSlider-thumb': {
                                color: "#454545"
                            },
                            '& .MuiSlider-track': {
                                color: "#575757"
                            },
                            '& .MuiSlider-rail': {
                                color: "#5f5f5f"
                            },
                        }}
                    />
                    <Input
                        disabled={props.isNewWorkRegistration ? !PermissionManager.NewWorkRegistration.areaTo : !PermissionManager.WorkRegistrationEditing.areaTo}
                        value={meterToKilometer(Number(props.unitInputFieldFirstLevel))}
                        size="medium"
                        onChange={(event) => handleSliderInputChange(Number(event.target.value), false)}
                        inputProps={{
                            step: 0.100,
                            min: minValue <= maxValue ? minValue : maxValue,
                            max: minValue <= maxValue ? maxValue : minValue,
                            type: 'number',
                            'aria-labelledby': 'input-slider-left',
                        }}
                        startAdornment={<InputAdornment position="start">km</InputAdornment>}
                        sx={{marginLeft: 2, width: 165, height: 30}}
                    />
                </Stack>
            </Stack>
        );
    }
    const renderKoralmLocationAndObjectTypeSelection = () => {
        return (
            <Stack paddingTop={selectComponentPaddings} direction="row" spacing={2}>
                <ToggleButtonGroup
                    value={props.locationType}
                    exclusive
                    disabled={!props.isNewWorkRegistration}
                    onChange={handleKoralmLocationToggle}
                    sx={{height: 55}}
                >
                    <ToggleButton sx={{width: 130}} value={KoralmLocation.Tunnel}
                                  disabled={!(props.isNewWorkRegistration ? PermissionManager.NewWorkRegistration.tunnel : PermissionManager.WorkRegistrationEditing.tunnel)}>
                        {LocalizationService.KoralmLocation(KoralmLocation.Tunnel)}
                    </ToggleButton>
                    <ToggleButton sx={{width: 130}} value={KoralmLocation.OpenLine}
                                  disabled={!(props.isNewWorkRegistration ? PermissionManager.NewWorkRegistration.openLine : PermissionManager.WorkRegistrationEditing.openLine)}>
                        {LocalizationService.KoralmLocation(KoralmLocation.OpenLine)}
                    </ToggleButton>
                </ToggleButtonGroup>
                <Select
                    name="WorkObjectTypeSelection"
                    value={props.selectedObjectType}
                    onChange={handleObjectTypeSelection}
                    sx={{width: 175, height: 55}}
                    MenuProps={{
                        sx: {
                            "&& .Mui-selected": {
                                backgroundColor: SELECTED_MENU_ITEM
                            }
                        }
                    }}
                    disabled={
                        (props.isNewWorkRegistration && !PermissionManager.NewWorkRegistration.objects) ||
                        (!props.isNewWorkRegistration && !PermissionManager.WorkRegistrationEditing.objects)
                    }
                >
                    {menuOptions}
                </Select>
            </Stack>
        );
    }
    const renderRailTrackStatus = () => {
        return (
            <Stack paddingLeft={17} paddingRight={4}>
                <Typography>Streckenstatus</Typography>
                <ToggleButtonGroup
                    onChange={(_, value) => props.changedRailTrackStatus(value as RailTrackStatus ?? props.railTrackStatus)}
                    value={props.railTrackStatus}
                    exclusive
                    style={{height: 40}}
                >
                    <ToggleButton
                        value={RailTrackStatus.Free}
                        disabled={props.isNewWorkRegistration ? !PermissionManager.NewWorkRegistration.railTrackStatus : !PermissionManager.WorkRegistrationEditing.railTrackStatus}
                        style={{backgroundColor: props.railTrackStatus === RailTrackStatus.Free ? railTrackStatusToColors(RailTrackStatus.Free) : 'inherit'}}>
                        {LocalizationService.RailTrackStatus(RailTrackStatus.Free)}
                    </ToggleButton>
                    <ToggleButton
                        value={RailTrackStatus.WorkInRailTrackAreaPeriphery}
                        disabled={props.isNewWorkRegistration ? !PermissionManager.NewWorkRegistration.railTrackStatus : !PermissionManager.WorkRegistrationEditing.railTrackStatus}
                        style={{backgroundColor: props.railTrackStatus === RailTrackStatus.WorkInRailTrackAreaPeriphery ? railTrackStatusToColors(RailTrackStatus.WorkInRailTrackAreaPeriphery) : 'inherit'}}>
                        {LocalizationService.RailTrackStatus(RailTrackStatus.WorkInRailTrackAreaPeriphery)}
                    </ToggleButton>
                    <ToggleButton
                        value={RailTrackStatus.Blocked}
                        disabled={props.isNewWorkRegistration ? !PermissionManager.NewWorkRegistration.railTrackStatus : !PermissionManager.WorkRegistrationEditing.railTrackStatus}
                        style={{backgroundColor: props.railTrackStatus === RailTrackStatus.Blocked ? railTrackStatusToColors(RailTrackStatus.Blocked) : 'inherit'}}>
                        {LocalizationService.RailTrackStatus(RailTrackStatus.Blocked)}
                    </ToggleButton>
                </ToggleButtonGroup>
            </Stack>
        );
    }
    const renderTravelDirection = () => {
        return (
            props.selectedObjectType === TunnelObject.Portal ||
            props.selectedObjectType === TunnelObject.CrossCut ||
            props.selectedObjectType === TunnelObject.VentilationShaft ?
                <div style={{width: 130}}/> :
                <Stack>
                    <Typography>Laufrichtung:</Typography>
                    <Stack direction="row" spacing={1}>
                        <Typography paddingTop={1}>Ost</Typography>
                        <Box sx={{width: 55}}>
                            <IconButton
                                disabled={props.isNewWorkRegistration ? !PermissionManager.NewWorkRegistration.direction : !PermissionManager.WorkRegistrationEditing.direction}
                                onClick={handleTravelDirectionToggle}
                            >
                                {buttonIconToUse}
                            </IconButton>
                        </Box>
                        <Typography paddingTop={1}>West</Typography>
                    </Stack>
                </Stack>
        );
    }

    const handleOpenLineLocationChange = (event: SelectChangeEvent<LatitudinalDirection>) => {
        props.changedLatitudinalDirectionConstruction(event.target.value as LatitudinalDirection)
        handleSliderInputChange(Number((minValue + maxValue) / 2), false)
        handleSliderInputChange(Number((minValue + maxValue) / 2), true)
    }

    const renderOpenLineConstructionSelection = () => {
        return (
            <Stack direction="row">
                <Select
                    name="OpenLineConstructionDirectionSelect"
                    onChange={handleOpenLineLocationChange}
                    value={props.latitudinalDirectionConstruction}
                    sx={{width: 100, height: 55, marginRight: 1}}
                    MenuProps={{
                        sx: {
                            "&& .Mui-selected": {
                                backgroundColor: SELECTED_MENU_ITEM
                            }
                        }
                    }}
                >
                    {LatitudinalDirectionMenuItems()}
                </Select>
                <Select
                    name="OpenLineConstructionSelect"
                    onChange={(event) => props.changedOutdoorBuilding(event.target.value as OutdoorBuildingTypes)}
                    value={props.outdoorBuilding}
                    sx={{width: 275, height: 55, marginRight: 5}}
                    MenuProps={{
                        sx: {
                            "&& .Mui-selected": {
                                backgroundColor: SELECTED_MENU_ITEM
                            }
                        }
                    }}
                >
                    {OutdoorBuildingMenuItems(props.latitudinalDirectionConstruction)}
                </Select>
            </Stack>
        );
    }

    return (
        <Stack
            direction="column"
            spacing={1}
            padding={2}
            margin={2}
            sx={{bgcolor: 'secondary.main', borderRadius: '5px'}}>
            <Stack direction="row" spacing={5} justifyContent="flex-start">
                {renderKoralmLocationAndObjectTypeSelection()}
                <Stack paddingTop={selectComponentPaddings} direction="row" spacing={1}>
                    {renderSecondLevelInputField(props.locationType, props.selectedObjectType)}
                    {Object.hasOwn(RailNumber, props.selectedObjectType) && renderOpenLineConstructionSelection()}
                    {railDescriptionService.railSectionDescriptions[0] && renderFirstLevelInputField(props.locationType, props.selectedObjectType)}
                </Stack>
            </Stack>
            {
                props.selectedObjectType === TunnelObject.EmergencyStop ||
                props.selectedObjectType === TunnelObject.EscapeGallery ?
                    <Stack height={66}></Stack> :
                    <Stack direction="row" paddingLeft={1}>
                        {renderTravelDirection()}
                        {renderRailTrackStatus()}
                        {(props.selectedObjectType === RailNumber.Eins || props.selectedObjectType === RailNumber.Zwei) && renderOpenLineValueInputs()}
                    </Stack>
            }
        </Stack>

    )
}

export default LengthSelectors;