import React, {useCallback, useEffect, useState} from "react"
import {Box, Stack, Typography} from "@mui/material";
import DefaultDataGrid from "../DefaultDataGrid/DefaultDataGrid";
import {AdminGroupDataApiFp, GroupData} from "../../generated";
import {GridValueGetterParams} from "@mui/x-data-grid/models/params/gridCellParams";
import {GridRowSpacingParams} from "@mui/x-data-grid";


const columns = [
    {field: "ID", headerName: "ID", hide: true, filterable: false, sortable: false},
    {
        field: "name", headerName: "Name", minWidth: 150,
        valueGetter: (params: GridValueGetterParams) => params.row.Name
    }
]

interface RoleData {
    ID: string;
    Name: string;
}

interface RolesListProps {
    availableGroups: GroupData[];
}

function RolesList({availableGroups}: RolesListProps) {
    const roles = availableGroups.map(group => ({ID: group.Id, Name: group.Name}));

    const getRowSpacing = useCallback((params: GridRowSpacingParams) => {
        return {
            top: params.isFirstVisible ? 0 : 5,
            bottom: params.isLastVisible ? 0 : 5,
        };
    }, []);


    return <Stack spacing={2}>
        <Box sx={{width: 215, height: 45}}>
            <Typography variant="h4" color="black" paddingTop={0.5}>
                Rollen
            </Typography>
        </Box>
        <Box sx={{height: '100%', width: '100%', display: 'flex', flexDirection: 'column', flexGrow: 1}}>
            <DefaultDataGrid mode="client" rows={roles} columns={columns} getRowSpacing={getRowSpacing}/>
        </Box>
    </Stack>
}

export default RolesList