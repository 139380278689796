import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Select,
    Stack,
    Typography
} from "@mui/material"
import React, {useState} from "react";
import {SaveButtonArgs} from "../../utils/InterfaceProps";
import {
    KoralmLocation,
    LatitudinalDirectedOutdoorConstructionWorkRegistrationApiFp,
    LatitudinalDirectedTunnelWorkRegistrationApiFp,
    OutdoorUniqueConstructionWorkRegistrationApiFp,
    OutdoorWorkRegistrationApiFp,
    ProcessStatus,
    Tube,
    TunnelObject,
    TunnelWorkRegistrationWithNumericLocationIDApiFp,
    ZoneBasedTunnelTubeWorkRegistrationApiFp
} from "../../generated";
import {PermissionManager} from "../../utils/PermissionManager";
import {StatusMenuItems} from "../../utils/Exports";
import WeekOverviewGrid from "../WeekOverviewPage/WeekOverviewGrid";
import {HeaderBarDefault, HeaderBarNewRegistration} from "../../utils/Colors";
import {createConflictPopUpArgs} from "../../utils/FilterHelpers";
import {formatISO} from "date-fns";
import {filterArgumentTypesWithCombinedFilterDescription} from "../../utils/Types";

function SaveButtons(props: SaveButtonArgs) {
    const [open, setOpen] = useState(false)
    const [outdoorWorkRegistration, setOutdoorWorkRegistration] = useState<any[]>([]);
    const [tunnelWorkRegistration, setTunnelWorkRegistration] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(false)

    const conflictWorkRegistrations = () => {
        return (
            <Stack>
                <WeekOverviewGrid
                    changedSelectedWorkRegistration={() => {}}
                    view={props.location === KoralmLocation.Tunnel ? KoralmLocation.Tunnel : props.openLineLocation}
                    startDate={props.range[0].startDate!}
                    endDate={props.range[0].endDate!}
                    changedOutdoorWorkRegistration={(values) => {
                        setOutdoorWorkRegistration(values)
                    }}
                    changedTunnelWorkRegistration={(values) => {
                        setTunnelWorkRegistration(values)
                    }}
                    showButtons={false}
                    isConflictTable={createConflictPopUpArgs(formatISO(props.range[0].startDate!), formatISO(props.range[0].endDate!), props.object, props.area, props.area2, props.uuId, props.openLineLocation)}
                />
            </Stack>
        )
    }

    const handleSaveClick = async () => {
        setIsLoading(true);
        const numberOfConflicts = await checkForConflicts();
        setIsLoading(false);

        if (numberOfConflicts > 0) {
            setOpen(true)
        } else {
            props.changedSaveButton()
        }
    }

    const checkForConflicts = async () => {
        const filterArgs: filterArgumentTypesWithCombinedFilterDescription = createConflictPopUpArgs(formatISO(props.range[0].startDate!), formatISO(props.range[0].endDate!), props.object, props.area, props.area2, props.uuId, props.openLineLocation)
        if (props.location === KoralmLocation.Tunnel) {
            let crossCutResponse: number = 0;
            let latitudinalResponse: number = 0;
            let zoneResponse: number = 0;
            switch (props.object) {
                case TunnelObject.Tube:
                case Tube.South:
                case Tube.North:
                    zoneResponse = await ZoneBasedTunnelTubeWorkRegistrationApiFp()
                        .zoneBasedTunnelTubeWorkRegistrationGetByUuIdLastChangedWithCombinedFilterAndGetData(...filterArgs).then(value => (value.ItemCount));
                    break;
                case TunnelObject.EscapeGallery:
                case TunnelObject.CrossCut:
                case TunnelObject.EmergencyStop:
                    crossCutResponse = await TunnelWorkRegistrationWithNumericLocationIDApiFp()
                        .tunnelWorkRegistrationWithNumericLocationIDGetByUuIdLastChangedWithCombinedFilterAndGetData(...filterArgs).then(value => (value.ItemCount));
                    break;
                case TunnelObject.VentilationShaft:
                case TunnelObject.Portal:
                    latitudinalResponse = await LatitudinalDirectedTunnelWorkRegistrationApiFp()
                        .latitudinalDirectedTunnelWorkRegistrationGetByUuIdLastChangedWithCombinedFilterAndGetData(...filterArgs).then(value => (value.ItemCount));
                    break;
            }
            return crossCutResponse + latitudinalResponse + zoneResponse
        } else {
            let outdoorResponse = await OutdoorWorkRegistrationApiFp()
                .outdoorWorkRegistrationGetByUuIdLastChangedWithCombinedFilterAndGetData(...filterArgs);
            let outdoorUniqueResponse = await OutdoorUniqueConstructionWorkRegistrationApiFp()
                .outdoorUniqueConstructionWorkRegistrationGetByUuIdLastChangedWithCombinedFilterAndGetData(...filterArgs);
            let latitudinalOutdoorResponse = await LatitudinalDirectedOutdoorConstructionWorkRegistrationApiFp()
                .latitudinalDirectedOutdoorConstructionWorkRegistrationGetByUuIdLastChangedWithCombinedFilterAndGetData(...filterArgs);

            return outdoorResponse.ItemCount + outdoorUniqueResponse.ItemCount + latitudinalOutdoorResponse.ItemCount
        }
    }

    return (
        <Stack>
            <Stack direction="row" spacing={2} paddingBottom={2} paddingLeft={2} paddingRight={3}>
                <Select
                    name="StatusSelection"
                    value={props.status}
                    fullWidth={true}
                    color="secondary"
                    onChange={(event) => {
                        props.changedStatus(event.target.value as ProcessStatus);
                    }}
                    sx={{
                        bgcolor: "secondary.main"
                    }}
                >
                    {StatusMenuItems(props.isNewWorkRegistration)}
                </Select>
                <Button
                    variant='contained'
                    color="secondary"
                    fullWidth={true}
                    onClick={handleSaveClick}
                    disabled={!props.wasEdited ||
                        (props.wasEdited && !(
                            props.isNewWorkRegistration ?
                                PermissionManager.NewWorkRegistration.safeChanges :
                                PermissionManager.WorkRegistrationEditing.safeChanges))}
                >{props.saveValue}
                    {isLoading && <CircularProgress style={{color: HeaderBarDefault}}/>}
                </Button>
                <Dialog
                    open={open}
                    onClose={() => setOpen(false)}
                    sx={{
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: "100%",
                                maxWidth: "1700px",
                            },
                        },
                    }}
                >
                    <DialogTitle><Typography
                        fontSize={26}>{(outdoorWorkRegistration.length === 0 && tunnelWorkRegistration.length === 0) && "Keine "}Konflikte</Typography></DialogTitle>
                    <DialogContent>
                        {conflictWorkRegistrations()}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            size="large"
                            variant='contained'
                            onClick={() => setOpen(false)}>Abbrechen</Button>
                        <Button
                            size="large"
                            variant='contained'
                            style={{backgroundColor: HeaderBarNewRegistration}}
                            onClick={() => {
                                setOpen(false)
                                props.changedSaveButton();
                            }}>
                            {(outdoorWorkRegistration.length > 0 || tunnelWorkRegistration.length > 0) && "Trotzdem "}
                            {props.saveValue}</Button>
                    </DialogActions>
                </Dialog>
            </Stack>
        </Stack>
    )
}

export default SaveButtons;