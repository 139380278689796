import Keycloak from "keycloak-js"
import {keycloakConfig} from "../keycloak";
import {Roles} from "../utils/Types";
import {PermissionManager} from "../utils/PermissionManager";

const _kc = new Keycloak(keycloakConfig)

const minValidity = 5;

const initKeycloak = (onAuthenticatedCallback: () => void) => {
    _kc.onAuthSuccess = onAuthenticatedCallback;
    _kc.onTokenExpired = async () => await updateToken();
    const createErrorHandler = (errorState: string) => () => {
        _kc.login();
        console.error("Error during authentication. This happened during: " + errorState)
    };
    _kc.onAuthError = () => createErrorHandler('first login');
    _kc.onAuthRefreshError = createErrorHandler('token refresh');
    _kc.init({onLoad: 'login-required', checkLoginIframe: false});
};

const doLogout = _kc.logout;

const getToken = () => _kc.token;

const isLoggedIn = () => !!_kc.token;

const tokenIsExpired = () => _kc.isTokenExpired(minValidity);

const updateToken = () => _kc.updateToken(minValidity)
    .then((success) => {
        return success;
    })
    .catch((error) => {
        console.error(error);
        return false;
    });

const getUsername = () => _kc.tokenParsed?.preferred_username;
const getFirstName = () => _kc.tokenParsed?.given_name;
const getLastName = () => _kc.tokenParsed?.family_name;
const getEmail = () => _kc.tokenParsed?.email;
const getPhoneNumber = () => _kc.tokenParsed?.phone_number;
const getIsIbn = () => (_kc.tokenParsed?.ibn !== undefined) ? _kc.tokenParsed.ibn : false;
const tryGetMappedCompanies = () => _kc.tokenParsed?.companies as (string[] | null);
const hasRole = (roles: any[]) => roles.some((role) => _kc.hasRealmRole(role));
const getUserRoles = () => _kc.tokenParsed?.realm_access?.roles;
const tryGetCurrentContractorId = () => {
    const mappedCompanies = hasRole([Roles.Subcontractor]) ? tryGetMappedCompanies() : null;
    return mappedCompanies && mappedCompanies.length > 0 ? mappedCompanies[0] : null;
};
const tryGetCurrentContractorOrMappedCompanies = (): string[] | null => {
    const contractorId = UserService.tryGetCurrentContractorId();
    return PermissionManager.Universal.getContractorsOrCompanies ?
        UserService.tryGetMappedCompanies() :
        contractorId !== null ? [contractorId] : null;
}

const UserService = {
    initKeycloak,
    doLogout,
    isLoggedIn,
    getToken,
    updateToken,
    getUsername,
    getFirstName,
    getLastName,
    getEmail,
    getPhoneNumber,
    getIsIbn,
    hasRole,
    getUserRoles,
    tokenIsExpired,
    tryGetCurrentContractorId,
    tryGetMappedCompanies,
    tryGetCurrentContractorOrMappedCompanies,
};

export default UserService;