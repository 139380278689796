import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    Snackbar,
    TextField,
    Tooltip,
    Typography,
    useTheme
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {AdminUserDataApiFp, GroupData, UserContact, UserOutput} from "../../generated";
import EditIcon from "@mui/icons-material/Edit";
import {ContractorDropdown, RoleDropdown} from "./PopupComponents";
import {UserData} from "./UserList";
import {
    emailRegex,
    invalidFirstnameMessage,
    invalidLastnameMessage,
    invalidMailMessage,
    invalidPasswordMessage, passwordRegex
} from "./UserManagementConstants";

interface UserEditPopupProps {
    editingUserId: string | null,
    setEditingUser: (open: string | null) => void,
    user: UserOutput,
    availableGroups: GroupData[]
    allUsers: UserData[]
}

export function UserEditPopupButton({editingUserId, setEditingUser, user, availableGroups, allUsers}: UserEditPopupProps) {
    const theme = useTheme();

    const username = user.Data.Username;
    const [firstName, setFirstName] = useState(user.Data.FirstName);
    const [lastName, setLastName] = useState(user.Data.LastName);
    const [email, setEmail] = useState(user.Data.Email);
    const [selectedGroup, setSelectedGroup] = useState<GroupData | null>(null);
    const [selectedContractor, setSelectedContractor] = useState(user.Data.Companies);
    const [successMessage, setSuccessMessage] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [resetPasswordDialogOpen, setResetPasswordDialogOpen] = useState(false);
    const [tempPassword, setTempPassword] = useState("");

    const [errorFields, setErrorFields] = useState({
        firstName: false,
        lastName: false,
        email: false,
        role: false
    });
    const [tempPwErrorField, setTempPwErrorField] = useState(false);

    const [initialGroup, setInitialGroup] = useState<GroupData | null>(null);
    const [initialContact] = useState<UserContact>({FirstName: user.Data.FirstName, LastName: user.Data.LastName, Email: user.Data.Email});
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        AdminUserDataApiFp()
            .adminUserDataGetUserGroupsAndGetData(user.Id)
            .then(data => {
                const group = data.length > 0 ? data[0] : null;
                setSelectedGroup(group);
                setInitialGroup(group);
            })
            .catch(e => console.log("Error fetching user group:", e))
    }, [user.Id]);

    const resetForm = () => {
        setFirstName(user.Data.FirstName);
        setLastName(user.Data.LastName);
        setEmail(user.Data.Email);
        setSelectedGroup(initialGroup);
        setSelectedContractor(user.Data.Companies);
        setTempPassword('');
        setErrorFields({
            firstName: false,
            lastName: false,
            email: false,
            role: false
        });
        setTempPwErrorField(false)
    };

    const handleSave = async () => {
        setIsLoading(true);

        const errors = {firstName: false, lastName: false, email: false, role: false};
        if (!firstName) errors.firstName = true;
        if (!lastName) errors.lastName = true;
        if (!email || !emailRegex.test(email)
            || allUsers.find(u => u.Output.Id !== user.Id && u.Output.Data.Email === email)) errors.email = true;
        if (!selectedGroup) errors.role = true;

        setErrorFields(errors);

        if (Object.values(errors).some((error) => error)) {
            setIsLoading(false);
            return;
        }

        function equalElements(arr1: string[], arr2: string[]) {
            return arr1.length === arr2.length &&
                arr1.sort().every((value, index) => value === arr2.sort()[index]);
        }

        const promises = [];

        if (!equalElements(selectedContractor, user.Data.Companies)) {
            const updateCompaniesPromise = AdminUserDataApiFp()
                .adminUserDataUpdateUserCompaniesAndGetData(selectedContractor, user.Id);
            promises.push(updateCompaniesPromise);
        }

        if (selectedGroup && selectedGroup !== initialGroup) {
            const updateGroupsPromise = AdminUserDataApiFp().adminUserDataUpdateUserGroupAndGetData(user.Id, selectedGroup.Id);
            promises.push(updateGroupsPromise);
        }

        if (initialContact && (firstName !== initialContact.FirstName || lastName !== initialContact.LastName || email !== initialContact.Email)) {
            const updateContactInfoPromise = AdminUserDataApiFp()
                .adminUserDataUpdateUserContactInfoAndGetData({
                    FirstName: firstName,
                    LastName: lastName,
                    Email: email
                }, user.Id);
            promises.push(updateContactInfoPromise);
        }

        if (promises.length > 0) {
            Promise.all(promises)
                .then(() => {
                    setEditingUser(null);
                    setSuccessMessage(true);
                })
                .catch((error) => console.error("Error updating user: ", error))
                .finally(() => setIsLoading(false));
        } else {
            setIsLoading(false);
        }
    };

    const handleDeleteConfirmation = () => {
        setDeleteDialogOpen(true);
        setResetPasswordDialogOpen(false);
    };

    const handleDelete = async () => {
        setIsLoading(true);
        AdminUserDataApiFp()
            .adminUserDataDeleteUserAndGetData(user.Id)
            .then(() => {
                setEditingUser(null);
                setSuccessMessage(true);
            })
            .catch(e => console.error('Error deleting user:', e))
            .finally(() => setIsLoading(false));
    };


    const handleResetPassword = async () => {
        if (!tempPassword || !passwordRegex.test(tempPassword)) {
            setTempPwErrorField(true);
            return;
        }

        setIsLoading(true);
        AdminUserDataApiFp()
            .adminUserDataResetPasswordAndGetData(user.Id, tempPassword)
            .then(() => {
                setResetPasswordDialogOpen(false);
                setEditingUser(null);
                setSuccessMessage(true);
            })
            .catch(e => console.error('Error resetting password:', e))
            .finally(() => setIsLoading(false));
    };

    const handleOpenResetPasswordDialog = () => {
        setResetPasswordDialogOpen(true);
        setDeleteDialogOpen(false);
    };

    return (
        <>
            <Tooltip title={<Typography>Bearbeiten</Typography>}>
                <IconButton
                    size="large"
                    sx={{color: "#666a80"}}
                    onClick={() => setEditingUser(user.Id)}>
                    <EditIcon fontSize="large"/>
                </IconButton>
            </Tooltip>
            <Dialog open={editingUserId === user.Id} onClose={() => setEditingUser(null)}>
                <DialogTitle>Nutzer bearbeiten</DialogTitle>
                <DialogContent>
                    <Box component="form" noValidate autoComplete="off">
                        <FormControl fullWidth margin="normal">
                            <TextField
                                label="Nutzername"
                                variant="outlined"
                                required
                                value={username}
                                disabled
                            />
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <TextField
                                label="Vorname"
                                variant="outlined"
                                required
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                error={errorFields.firstName}
                                helperText={errorFields.firstName ? invalidFirstnameMessage : ''}
                            />
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <TextField
                                label="Nachname"
                                variant="outlined"
                                required
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                error={errorFields.lastName}
                                helperText={errorFields.lastName ? invalidLastnameMessage : ''}
                            />
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <TextField
                                label="Email"
                                variant="outlined"
                                required
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                error={errorFields.email}
                                helperText={errorFields.email ? invalidMailMessage : ''}
                            />
                        </FormControl>
                        <RoleDropdown
                            selectedGroup={selectedGroup}
                            availableGroups={availableGroups}
                            theme={theme} errorFields={errorFields}
                            setSelectedGroup={setSelectedGroup}
                        />
                        <ContractorDropdown
                            selectedContractor={selectedContractor}
                            setSelectedContractor={setSelectedContractor}
                        />
                    </Box>
                </DialogContent>
                <DialogActions sx={{
                    paddingLeft: theme.spacing(3),
                    paddingRight: theme.spacing(3),
                    paddingBottom: theme.spacing(3)
                }}>
                    <Button onClick={handleDeleteConfirmation}
                            variant="contained"
                            color="error"
                            sx={{marginRight: 'auto'}}
                            disabled={isLoading}
                    >
                        Löschen
                    </Button>
                    <Button onClick={handleOpenResetPasswordDialog}
                            variant="contained"
                            color="warning"
                            disabled={isLoading}
                    >
                        Passwort zurücksetzen
                    </Button>
                    <Button onClick={() => {
                        resetForm();
                        setEditingUser(null);
                    }} variant="contained" color="secondary" disabled={isLoading}>
                        Abbrechen
                    </Button>
                    <Button onClick={handleSave} variant="contained" color="primary" disabled={isLoading}>
                        Speichern
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
            >
                <DialogTitle>Löschen bestätigen</DialogTitle>
                <DialogContent>
                    <Typography>Möchten Sie den Nutzer <strong>{username}</strong> wirklich löschen?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialogOpen(false)} color="primary" disabled={isLoading}>
                        Abbrechen
                    </Button>
                    <Button onClick={handleDelete} color="error" disabled={isLoading}>
                        Bestätigen
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={resetPasswordDialogOpen}
                onClose={() => setResetPasswordDialogOpen(false)}
            >
                <DialogTitle>Passwort zurücksetzen</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth margin="normal">
                        <TextField
                            label="Temp Passwort"
                            variant="outlined"
                            required
                            value={tempPassword}
                            onChange={(e) => setTempPassword(e.target.value)}
                            error={tempPwErrorField}
                            helperText={tempPwErrorField ? invalidPasswordMessage : ''}
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setResetPasswordDialogOpen(false)} color="primary" disabled={isLoading}>
                        Abbrechen
                    </Button>
                    <Button onClick={handleResetPassword} color="warning" disabled={isLoading}>
                        Bestätigen
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={successMessage}
                autoHideDuration={3000}
                onClose={() => setSuccessMessage(false)}
            >
                <Alert onClose={() => setSuccessMessage(false)} severity="success" sx={{width: '100%'}}>
                    Aktion erfolgreich durchgeführt!
                </Alert>
            </Snackbar>
        </>
    );
}
