import {
    CombinedFilterDescription,
    FilterDescriptionCombinator,
    FilterOperation,
    LatitudinalDirection,
    Order,
    ProcessStatus,
    RailNumber,
    Tube,
    TunnelObject,
    WebFilterDescription
} from "../generated";
import UserService from "../services/UserService";
import {filterArgumentTypesWithCombinedFilterDescription, ObjectType} from "./Types";
import {ColumnHeaderService, DEFAULT_DATA_GRID_PAGE_SIZE} from "../constants";
import {railDescriptionService} from "../services/railDescriptionsProvider";

function createPopupFilter(start: string, end: string, openLineLocation?: LatitudinalDirection) {
    const filter: CombinedFilterDescription = {
        Combinator: FilterDescriptionCombinator.And,
        LeafDescriptions: [],
        ChildDescriptions: [{
            Combinator: FilterDescriptionCombinator.And,
            LeafDescriptions: [],
            ChildDescriptions: [{
                Combinator: FilterDescriptionCombinator.And,
                LeafDescriptions: [{
                    FilterOperation: FilterOperation.Equals,
                    FilterPropertyName: ColumnHeaderService.GetStatusValue(false),
                    FilterPropertyValues: [ProcessStatus.ChangeRejected]
                }],
                ChildDescriptions: [],
            },
                createIntervalFilter(start, end),
                {
                    Combinator: FilterDescriptionCombinator.And,
                    LeafDescriptions: [filterForAllNotDeleted],
                    ChildDescriptions: [],
                }
            ]
        }]
    };
    const currentContractorId = UserService.tryGetCurrentContractorId();
    if (currentContractorId) {
        filter.ChildDescriptions[0].ChildDescriptions.push({
            Combinator: FilterDescriptionCombinator.And,
            LeafDescriptions: [{
                FilterOperation: FilterOperation.Equals,
                FilterPropertyName: ColumnHeaderService.GetContractorValue(false),
                FilterPropertyValues: [currentContractorId]
            }],
            ChildDescriptions: []
        })
    }
    if (openLineLocation) {
        filter.ChildDescriptions.push(filterForOpenLineLocation(openLineLocation))
    }
    return filter;
}

function createUnfinishedWorkFilter(end: string, contractorIds: string | string[] | null, openLineLocation?: LatitudinalDirection) {
    const filter: CombinedFilterDescription = {
        Combinator: FilterDescriptionCombinator.And,
        LeafDescriptions: [],
        ChildDescriptions: [
            {
                Combinator: FilterDescriptionCombinator.And,
                ChildDescriptions: [{
                    Combinator: FilterDescriptionCombinator.Or,
                    ChildDescriptions: [],
                    LeafDescriptions: [
                        {
                            FilterOperation: FilterOperation.Equals,
                            FilterPropertyName: ColumnHeaderService.GetStatusValue(false),
                            FilterPropertyValues: [ProcessStatus.TaskCreatedByPlaner]
                        },
                        {
                            FilterOperation: FilterOperation.Equals,
                            FilterPropertyName: ColumnHeaderService.GetStatusValue(false),
                            FilterPropertyValues: [ProcessStatus.ChangeApproved]
                        }]
                }],
                LeafDescriptions: [],
            },
            {
                Combinator: FilterDescriptionCombinator.And,
                ChildDescriptions: [],
                LeafDescriptions: [filterForAllNotDeleted],
            },
            {
                Combinator: FilterDescriptionCombinator.And,
                ChildDescriptions: [],
                LeafDescriptions: [{
                    FilterOperation: FilterOperation.LessThanOrEqual,
                    FilterPropertyName: "End",
                    FilterPropertyValues: [end]
                }]
            },
        ]
    }
    if (contractorIds) {
        filter.ChildDescriptions.push({
            Combinator: FilterDescriptionCombinator.And,
            ChildDescriptions: [],
            LeafDescriptions: [{
                FilterOperation: FilterOperation.Equals,
                FilterPropertyName: ColumnHeaderService.GetContractorValue(false),
                FilterPropertyValues: typeof contractorIds === 'string' ? [contractorIds] : contractorIds,
            }]
        })
    }
    if (openLineLocation) {
        filter.ChildDescriptions.push(filterForOpenLineLocation(openLineLocation))
    }
    return filter;
}

function createIntervalFilter(start: string, end: string, forAreaFiltering = false): CombinedFilterDescription {
    let intervalFilter: CombinedFilterDescription;
    intervalFilter = {
        Combinator: FilterDescriptionCombinator.Or,
        LeafDescriptions: [],
        ChildDescriptions: [
            {
                Combinator: FilterDescriptionCombinator.And,
                LeafDescriptions: [
                    {
                        FilterOperation: FilterOperation.GreaterThanOrEqual,
                        FilterPropertyName: forAreaFiltering ? "LocationDescription.ObjectDescription.AreaDescription.Start" : "Start",
                        FilterPropertyValues: [start]
                    },
                    {
                        FilterOperation: FilterOperation.LessThanOrEqual,
                        FilterPropertyName: forAreaFiltering ? "LocationDescription.ObjectDescription.AreaDescription.End" : "End",
                        FilterPropertyValues: [end]
                    },
                ],
                ChildDescriptions: [],
            },
            {
                Combinator: FilterDescriptionCombinator.And,
                LeafDescriptions: [
                    {
                        FilterOperation: FilterOperation.GreaterThanOrEqual,
                        FilterPropertyName: forAreaFiltering ? "LocationDescription.ObjectDescription.AreaDescription.End" : "End",
                        FilterPropertyValues: [start]
                    },
                    {
                        FilterOperation: FilterOperation.LessThanOrEqual,
                        FilterPropertyName: forAreaFiltering ? "LocationDescription.ObjectDescription.AreaDescription.End" : "End",
                        FilterPropertyValues: [end]
                    },
                ],
                ChildDescriptions: [],
            },
            {
                Combinator: FilterDescriptionCombinator.And,
                LeafDescriptions: [
                    {
                        FilterOperation: FilterOperation.GreaterThanOrEqual,
                        FilterPropertyName: forAreaFiltering ? "LocationDescription.ObjectDescription.AreaDescription.Start" : "Start",
                        FilterPropertyValues: [start]
                    },
                    {
                        FilterOperation: FilterOperation.LessThanOrEqual,
                        FilterPropertyName: forAreaFiltering ? "LocationDescription.ObjectDescription.AreaDescription.Start" : "Start",
                        FilterPropertyValues: [end]
                    },
                ],
                ChildDescriptions: [],
            },
            {
                Combinator: FilterDescriptionCombinator.And,
                LeafDescriptions: [
                    {
                        FilterOperation: FilterOperation.LessThanOrEqual,
                        FilterPropertyName: forAreaFiltering ? "LocationDescription.ObjectDescription.AreaDescription.Start" : "Start",
                        FilterPropertyValues: [start]
                    },
                    {
                        FilterOperation: FilterOperation.GreaterThanOrEqual,
                        FilterPropertyName: forAreaFiltering ? "LocationDescription.ObjectDescription.AreaDescription.End" : "End",
                        FilterPropertyValues: [end]
                    },
                ],
                ChildDescriptions: [],
            },
        ]
    }

    return intervalFilter;
}

function createWeekOverviewGridFilter(start: string, end: string, openLineLocation?: LatitudinalDirection): CombinedFilterDescription {
    let filter: CombinedFilterDescription;
    filter = {
        Combinator: FilterDescriptionCombinator.And,
        LeafDescriptions: [],
        ChildDescriptions: [
            {
                Combinator: FilterDescriptionCombinator.And,
                LeafDescriptions: [filterForAllNotDeleted],
                ChildDescriptions: [],
            },
            {
                Combinator: FilterDescriptionCombinator.And,
                LeafDescriptions: [],
                ChildDescriptions: [createIntervalFilter(start, end)]
            },
        ],
    };
    if (openLineLocation) {
        filter.ChildDescriptions.push(filterForOpenLineLocation(openLineLocation))
    }
    return filter;
}

export function createListViewFilter(contractors: string[], operation: FilterOperation, name: string, values: string[], filterType?: string, endLocation?: string[]): CombinedFilterDescription {
    let filter: CombinedFilterDescription;
    if (endLocation) {
        filter = {
            Combinator: FilterDescriptionCombinator.And,
            LeafDescriptions: [],
            ChildDescriptions: [
                {
                    Combinator: FilterDescriptionCombinator.Or,
                    LeafDescriptions: [],
                    ChildDescriptions: [createIntervalFilter(endLocation[0], endLocation[1], true)]
                },
                {
                    Combinator: FilterDescriptionCombinator.And,
                    LeafDescriptions: [filterForAllNotDeleted],
                    ChildDescriptions: []
                }]
        }
        if (contractors.length > 0) filter.LeafDescriptions.push(filterForContractors(contractors))
    } else {
        filter = {
            Combinator: FilterDescriptionCombinator.And,
            LeafDescriptions: [],
            ChildDescriptions: [{
                Combinator: FilterDescriptionCombinator.And,
                LeafDescriptions: [{
                    FilterOperation: operation,
                    FilterPropertyName: name,
                    FilterPropertyValues: values,
                },
                    filterForAllNotDeleted],
                ChildDescriptions: [],
            }]
        }
        if (filterType) {
            filter.ChildDescriptions[0].LeafDescriptions.push({
                    FilterOperation: FilterOperation.Equals,
                    FilterPropertyName: "LocationDescription.ObjectDescription.TunnelObject",
                    FilterPropertyValues: [filterType]
                }
            )
        }
        if (contractors.length > 0){
            filter.ChildDescriptions[0].LeafDescriptions.push(filterForContractors(contractors))
        }
    }
    return filter
}

export function createEmptyCombinedFilterDescription(contractors: string[]): CombinedFilterDescription {
    let filter: CombinedFilterDescription;
    filter = {
        Combinator: FilterDescriptionCombinator.And,
        LeafDescriptions: [],
        ChildDescriptions: [
            {
                Combinator: FilterDescriptionCombinator.And,
                LeafDescriptions: [
                    filterForAllNotDeleted, emptyWebFilterDescription
                ],
                ChildDescriptions: [],
            },
        ],
    }
    if (contractors.length > 0) {
        filter.ChildDescriptions[0].LeafDescriptions.push(filterForContractors(contractors));
    }

    return filter;
}

const filterForContractors = (contractors: string[]): WebFilterDescription => ({
    FilterOperation: FilterOperation.OneOf,
    FilterPropertyName: ColumnHeaderService.GetContractorValue(false),
    FilterPropertyValues: contractors,
});

const emptyWebFilterDescription: WebFilterDescription = {
    FilterOperation: FilterOperation.NotEquals,
    FilterPropertyName: "Name",
    FilterPropertyValues: ["xYzEmptyFiltering"]
};

const filterForAllNotDeleted: WebFilterDescription = {
    FilterOperation: FilterOperation.Equals,
    FilterPropertyName: "IsDeleted",
    FilterPropertyValues: [false.toString()]
};

export function filterForOpenLineLocation(openLineLocation: LatitudinalDirection): CombinedFilterDescription {
    return {
        Combinator: FilterDescriptionCombinator.And,
        ChildDescriptions: [],
        LeafDescriptions: [{
            FilterOperation: openLineLocation === LatitudinalDirection.East ? FilterOperation.LessThanOrEqual: FilterOperation.GreaterThanOrEqual,
            FilterPropertyName: "LocationDescription.ObjectDescription.AreaDescription.Start",
            FilterPropertyValues: [railDescriptionService.railSectionDescriptions[0].RailAreaDescription.Start.siValue.toString()]
        }, {
            FilterOperation: openLineLocation === LatitudinalDirection.East ? FilterOperation.LessThanOrEqual: FilterOperation.GreaterThanOrEqual,
            FilterPropertyName: "LocationDescription.ObjectDescription.AreaDescription.End",
            FilterPropertyValues: [railDescriptionService.railSectionDescriptions[0].RailAreaDescription.Start.siValue.toString()]
        }]
    }
}

function createConflictPopUpFilter(start: string, end: string, object: ObjectType, areaId: string, areaId2: string, currentObjectUuId: string, openLineLocation?: LatitudinalDirection) {
    // Note FS: Do not change the structure without changing fetchTunnelDataConflictPopUp()
    let objectName: string
    let objectValue: string = object
    if (object === RailNumber.Zwei || object === RailNumber.Eins) {
        objectValue = "Openline"
        objectName = "LocationDescription.Location"
    } else if (object === Tube.North || object === Tube.South) {
        objectName = "LocationDescription.ObjectDescription.Tube"
    } else {
        objectName = "LocationDescription.ObjectDescription.TunnelObject"
    }

    const filter: CombinedFilterDescription = {
        Combinator: FilterDescriptionCombinator.And,
        LeafDescriptions: [],
        ChildDescriptions: [{
            Combinator: FilterDescriptionCombinator.And,
            ChildDescriptions: [],
            LeafDescriptions: [{
                FilterOperation: FilterOperation.GreaterThanOrEqual,
                FilterPropertyName: "Start",
                FilterPropertyValues: [start]
            }, {
                FilterOperation: FilterOperation.LessThanOrEqual,
                FilterPropertyName: "Start",
                FilterPropertyValues: [end]
            }, {
                FilterOperation: FilterOperation.Equals,
                FilterPropertyName: objectName,
                FilterPropertyValues: [objectValue],
            }, filterForAllNotDeleted,
                {
                    FilterOperation: FilterOperation.NotEquals,
                    FilterPropertyName: "UUID",
                    FilterPropertyValues: [currentObjectUuId]
                }]
        }]
    }
    if (
        object === TunnelObject.CrossCut ||
        object === TunnelObject.EmergencyStop ||
        object === TunnelObject.EscapeGallery ||
        object === TunnelObject.VentilationShaft ||
        object === TunnelObject.Portal) {
        filter.ChildDescriptions.push({
            Combinator: FilterDescriptionCombinator.And,
            ChildDescriptions: [],
            LeafDescriptions: [{
                FilterOperation: FilterOperation.Equals,
                FilterPropertyName: "LocationDescription.ObjectDescription.AreaDescription.ID",
                FilterPropertyValues: [areaId]
            }]
        })
    } else {
        const start = Math.min(Number(areaId), Number(areaId2));
        const end = Math.max(Number(areaId), Number(areaId2));
        const zoneArray: string[] = Array.from(
            {length: end - start + 1},
            (_, i) => (start + i).toString()
        );
        filter.ChildDescriptions.push({
            Combinator: FilterDescriptionCombinator.Or,
            ChildDescriptions: [],
            LeafDescriptions: [{
                FilterOperation: FilterOperation.OneOf,
                FilterPropertyName: "LocationDescription.ObjectDescription.AreaDescription.Start",
                FilterPropertyValues: zoneArray
            }, {
                FilterOperation: FilterOperation.OneOf,
                FilterPropertyName: "LocationDescription.ObjectDescription.AreaDescription.End",
                FilterPropertyValues: zoneArray
            }]
        })
        if (openLineLocation) {
            filter.ChildDescriptions.push(filterForOpenLineLocation(openLineLocation))
        }
    }
    return filter;
}

export function createPopUpArgs(start: string, end: string, openLineLocation?: LatitudinalDirection): filterArgumentTypesWithCombinedFilterDescription {
    return [DEFAULT_DATA_GRID_PAGE_SIZE, 0, "Start", Order.Ascending, createPopupFilter(start, end, openLineLocation)];
}

export function createUnfinishedWorkArgs(end: string, contractorIds: string | string[] | null, openLineLocation?: LatitudinalDirection): filterArgumentTypesWithCombinedFilterDescription {
    return [DEFAULT_DATA_GRID_PAGE_SIZE, 0, "Start", Order.Ascending, createUnfinishedWorkFilter(end, contractorIds, openLineLocation)];
}

export function createWeekOverviewArgs(start: string, end: string, sortProperty: string, sortingValue: Order, openLineLocation?: LatitudinalDirection): filterArgumentTypesWithCombinedFilterDescription {
    return [255, 0, sortProperty, sortingValue, createWeekOverviewGridFilter(start, end, openLineLocation)]
}

export function createConflictPopUpArgs(start: string, end: string, object: ObjectType, areaId: string, areaId2: string, currentObjectUuId: string, openLineLocation?: LatitudinalDirection): filterArgumentTypesWithCombinedFilterDescription {
    return [DEFAULT_DATA_GRID_PAGE_SIZE, 0, "Start", Order.Ascending, createConflictPopUpFilter(start, end, object, areaId, areaId2, currentObjectUuId, openLineLocation)];
}