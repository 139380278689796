import {Dialog, DialogContent, IconButton, Stack, Tooltip, Typography} from "@mui/material";
import React, {useEffect} from "react";
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import WeekOverviewGrid from "../WeekOverviewPage/WeekOverviewGrid";
import {KoralmLocation, LatitudinalDirection} from "../../generated";
import {LocalizationService} from "../../utils/Localization";
import {LOG_IN_EVENT_FLAG} from "../../constants";
import {PermissionManager} from "../../utils/PermissionManager";
import {
    HEADER_BAR_ICON_BUTTON_HEIGHT,
    HEADER_BAR_ICON_FONT_SIZE,
    HEADER_BAR_ICON_PADDING
} from "../../styling-constants";
import DialogTitleWithCloseButton from "./DialogTitleWithCloseButton";
import {createPopUpArgs} from "../../utils/FilterHelpers";
import {formatISO} from "date-fns";
import {WorkRegistration} from "../../utils/Types";
import {getLimitedEndDate} from "../../utils/Exports";

interface InfoPopUpArgs {
    shouldOpenOnLogin: boolean;
    newNotification: boolean;
    changedSelectedWorkRegistration: (value: WorkRegistration) => void;
}

function RejectedWorkRegistationsPopup(props: InfoPopUpArgs) {
    const [open, setOpen] = React.useState(false);

    const startDate = new Date()
    const endDate = new Date(startDate.getDate() + 4 * 7);

    useEffect(() => {
        if (props.newNotification && props.shouldOpenOnLogin && PermissionManager.Universal.showInfoPopUp) {
            setOpen(true)
            localStorage.setItem(LOG_IN_EVENT_FLAG, "false")
        }
    }, [props.newNotification, props.shouldOpenOnLogin])

    const deniedWorkRegistrations = () => {
        return (
            <Stack>
                <Typography align="center"
                            fontSize={20}>{LocalizationService.KoralmLocationWithLatitudinalDirection()}</Typography>
                <WeekOverviewGrid
                    changedSelectedWorkRegistration={props.changedSelectedWorkRegistration}
                    view={KoralmLocation.Tunnel}
                    startDate={startDate}
                    endDate={endDate}
                    isPopUP={createPopUpArgs(formatISO(startDate), formatISO(endDate))}
                    changedOutdoorWorkRegistration={() => {
                    }}
                    changedTunnelWorkRegistration={() => {
                    }}
                />
                <Typography align="center"
                            fontSize={20}>{LocalizationService.KoralmLocationWithLatitudinalDirection(LatitudinalDirection.East)}</Typography>
                <WeekOverviewGrid
                    changedSelectedWorkRegistration={props.changedSelectedWorkRegistration}
                    view={LatitudinalDirection.East}
                    startDate={startDate}
                    endDate={endDate}
                    isPopUP={createPopUpArgs(formatISO(startDate), formatISO(endDate), LatitudinalDirection.East)}
                    changedOutdoorWorkRegistration={() => {
                    }}
                    changedTunnelWorkRegistration={() => {
                    }}
                />
                <Typography align="center"
                            fontSize={20}>{LocalizationService.KoralmLocationWithLatitudinalDirection(LatitudinalDirection.West)}</Typography>
                <WeekOverviewGrid
                    changedSelectedWorkRegistration={props.changedSelectedWorkRegistration}
                    view={LatitudinalDirection.West}
                    startDate={startDate}
                    endDate={endDate}
                    isPopUP={createPopUpArgs(formatISO(startDate), formatISO(endDate), LatitudinalDirection.West)}
                    changedOutdoorWorkRegistration={() => {
                    }}
                    changedTunnelWorkRegistration={() => {
                    }}
                />
            </Stack>
        )
    }

    const title = (
        <Typography fontSize={20}>
            Abgelehnte Arbeiten im Zeitraum vom{" "}
            <Typography component="span" fontWeight="bold" fontSize={20}>
                {startDate.toLocaleDateString('de-DE', {
                    day: 'numeric',
                    month: 'long',
                })}
            </Typography>{" "}
            bis zum{" "}
            <Typography component="span" fontWeight="bold" fontSize={20}>
                {endDate.toLocaleDateString('de-DE', {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                })}
            </Typography>
        </Typography>
    );
    const tooltipTitle = "Abgelehnte Arbeiten";


    return (
        <div>
            <Tooltip title={<Typography>{tooltipTitle}</Typography>}>
                <IconButton
                    aria-label="Notification"
                    style={{color: props.newNotification ? "red" : "white", height: HEADER_BAR_ICON_BUTTON_HEIGHT}}
                    disabled={false}
                    onClick={() => setOpen(true)}>
                    {props.newNotification ?
                        <NotificationsActiveOutlinedIcon
                            style={{fontSize: HEADER_BAR_ICON_FONT_SIZE, padding: HEADER_BAR_ICON_PADDING}}/>
                        :
                        <NotificationsNoneOutlinedIcon
                            style={{fontSize: HEADER_BAR_ICON_FONT_SIZE, padding: HEADER_BAR_ICON_PADDING}}/>}
                </IconButton>
            </Tooltip>
            <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true} maxWidth={false}>
                <DialogTitleWithCloseButton title={title} onClose={() => setOpen(false)}/>
                <DialogContent>
                    {deniedWorkRegistrations()}
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default RejectedWorkRegistationsPopup;