/* tslint:disable */
/* eslint-disable */
/**
 * Koralm-Web-API
 * All UnitsOfMeasures are in <a href=\"https://en.wikipedia.org/wiki/International_System_of_Units\">SI base units</a>, unless otherwise described. The <code>siValue</code> property of those objects exists just for json serialisation purposes. All server side operations such as sorting and filtering operations may be performed on the property directly, not on its nested <code>siValue</code> property. <br>The Pagination index is zero-based. If a page is empty its index will be <code>-1</code>. Sorting and filter properties of nested objects are case-sensitive and seperated by the <code>.</code>-character as a delimiter.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DeserializationErrorInList } from '../model';
// @ts-ignore
import { GroupData } from '../model';
// @ts-ignore
import { UserContact } from '../model';
// @ts-ignore
import { UserInput } from '../model';
// @ts-ignore
import { UserOutput } from '../model';
/**
 * AdminUserDataApi - axios parameter creator
 * @export
 */
export const AdminUserDataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {UserInput} userInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserDataCreateUser: async (userInput: UserInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userInput' is not null or undefined
            assertParamExists('adminUserDataCreateUser', 'userInput', userInput)
            const localVarPath = `/AdminUserData/CreateUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserDataDeleteUser: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/AdminUserData/DeleteUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserDataGetUserById: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/AdminUserData/GetUserById`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [username] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserDataGetUserByName: async (username?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/AdminUserData/GetUserByName`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserDataGetUserGroups: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/AdminUserData/GetUserGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [tempPw] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserDataResetPassword: async (id?: string, tempPw?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/AdminUserData/ResetPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (tempPw !== undefined) {
                localVarQueryParameter['tempPw'] = tempPw;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserDataUpdateUserCompanies: async (requestBody: Array<string>, id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('adminUserDataUpdateUserCompanies', 'requestBody', requestBody)
            const localVarPath = `/AdminUserData/UpdateUserCompanies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserContact} userContact 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserDataUpdateUserContactInfo: async (userContact: UserContact, id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userContact' is not null or undefined
            assertParamExists('adminUserDataUpdateUserContactInfo', 'userContact', userContact)
            const localVarPath = `/AdminUserData/UpdateUserContactInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userContact, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [userId] 
         * @param {string} [groupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserDataUpdateUserGroup: async (userId?: string, groupId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/AdminUserData/UpdateUserGroup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (groupId !== undefined) {
                localVarQueryParameter['groupId'] = groupId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserDataUsers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/AdminUserData/Users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "API-Key", configuration)

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminUserDataApi - functional programming interface
 * @export
 */
export const AdminUserDataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminUserDataApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {UserInput} userInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserDataCreateUser(userInput: UserInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserDataCreateUser(userInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async adminUserDataCreateUserSendRequest(userInput: UserInput, options?: AxiosRequestConfig): Promise<AxiosResponse<File>> {
            return (await this.adminUserDataCreateUser(userInput,  options))();
        },
        async adminUserDataCreateUserAndGetData(userInput: UserInput, options?: AxiosRequestConfig): Promise<File> {
            return (await this.adminUserDataCreateUserSendRequest(userInput,  options)).data;
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserDataDeleteUser(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserDataDeleteUser(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async adminUserDataDeleteUserSendRequest(id?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<File>> {
            return (await this.adminUserDataDeleteUser(id,  options))();
        },
        async adminUserDataDeleteUserAndGetData(id?: string, options?: AxiosRequestConfig): Promise<File> {
            return (await this.adminUserDataDeleteUserSendRequest(id,  options)).data;
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserDataGetUserById(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserDataGetUserById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async adminUserDataGetUserByIdSendRequest(id?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<UserOutput>> {
            return (await this.adminUserDataGetUserById(id,  options))();
        },
        async adminUserDataGetUserByIdAndGetData(id?: string, options?: AxiosRequestConfig): Promise<UserOutput> {
            return (await this.adminUserDataGetUserByIdSendRequest(id,  options)).data;
        },
        /**
         * 
         * @param {string} [username] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserDataGetUserByName(username?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserDataGetUserByName(username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async adminUserDataGetUserByNameSendRequest(username?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<UserOutput>> {
            return (await this.adminUserDataGetUserByName(username,  options))();
        },
        async adminUserDataGetUserByNameAndGetData(username?: string, options?: AxiosRequestConfig): Promise<UserOutput> {
            return (await this.adminUserDataGetUserByNameSendRequest(username,  options)).data;
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserDataGetUserGroups(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GroupData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserDataGetUserGroups(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async adminUserDataGetUserGroupsSendRequest(id?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<GroupData>>> {
            return (await this.adminUserDataGetUserGroups(id,  options))();
        },
        async adminUserDataGetUserGroupsAndGetData(id?: string, options?: AxiosRequestConfig): Promise<Array<GroupData>> {
            return (await this.adminUserDataGetUserGroupsSendRequest(id,  options)).data;
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [tempPw] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserDataResetPassword(id?: string, tempPw?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserDataResetPassword(id, tempPw, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async adminUserDataResetPasswordSendRequest(id?: string, tempPw?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<File>> {
            return (await this.adminUserDataResetPassword(id, tempPw,  options))();
        },
        async adminUserDataResetPasswordAndGetData(id?: string, tempPw?: string, options?: AxiosRequestConfig): Promise<File> {
            return (await this.adminUserDataResetPasswordSendRequest(id, tempPw,  options)).data;
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserDataUpdateUserCompanies(requestBody: Array<string>, id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserDataUpdateUserCompanies(requestBody, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async adminUserDataUpdateUserCompaniesSendRequest(requestBody: Array<string>, id?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<File>> {
            return (await this.adminUserDataUpdateUserCompanies(requestBody, id,  options))();
        },
        async adminUserDataUpdateUserCompaniesAndGetData(requestBody: Array<string>, id?: string, options?: AxiosRequestConfig): Promise<File> {
            return (await this.adminUserDataUpdateUserCompaniesSendRequest(requestBody, id,  options)).data;
        },
        /**
         * 
         * @param {UserContact} userContact 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserDataUpdateUserContactInfo(userContact: UserContact, id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserDataUpdateUserContactInfo(userContact, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async adminUserDataUpdateUserContactInfoSendRequest(userContact: UserContact, id?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<File>> {
            return (await this.adminUserDataUpdateUserContactInfo(userContact, id,  options))();
        },
        async adminUserDataUpdateUserContactInfoAndGetData(userContact: UserContact, id?: string, options?: AxiosRequestConfig): Promise<File> {
            return (await this.adminUserDataUpdateUserContactInfoSendRequest(userContact, id,  options)).data;
        },
        /**
         * 
         * @param {string} [userId] 
         * @param {string} [groupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserDataUpdateUserGroup(userId?: string, groupId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserDataUpdateUserGroup(userId, groupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async adminUserDataUpdateUserGroupSendRequest(userId?: string, groupId?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<File>> {
            return (await this.adminUserDataUpdateUserGroup(userId, groupId,  options))();
        },
        async adminUserDataUpdateUserGroupAndGetData(userId?: string, groupId?: string, options?: AxiosRequestConfig): Promise<File> {
            return (await this.adminUserDataUpdateUserGroupSendRequest(userId, groupId,  options)).data;
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserDataUsers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserOutput>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserDataUsers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async adminUserDataUsersSendRequest(options?: AxiosRequestConfig): Promise<AxiosResponse<Array<UserOutput>>> {
            return (await this.adminUserDataUsers( options))();
        },
        async adminUserDataUsersAndGetData(options?: AxiosRequestConfig): Promise<Array<UserOutput>> {
            return (await this.adminUserDataUsersSendRequest( options)).data;
        },
    }
};

/**
 * AdminUserDataApi - factory interface
 * @export
 */
export const AdminUserDataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminUserDataApiFp(configuration)
    return {
        /**
         * 
         * @param {UserInput} userInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserDataCreateUser(userInput: UserInput, options?: any): AxiosPromise<File> {
            return localVarFp.adminUserDataCreateUser(userInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserDataDeleteUser(id?: string, options?: any): AxiosPromise<File> {
            return localVarFp.adminUserDataDeleteUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserDataGetUserById(id?: string, options?: any): AxiosPromise<UserOutput> {
            return localVarFp.adminUserDataGetUserById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [username] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserDataGetUserByName(username?: string, options?: any): AxiosPromise<UserOutput> {
            return localVarFp.adminUserDataGetUserByName(username, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserDataGetUserGroups(id?: string, options?: any): AxiosPromise<Array<GroupData>> {
            return localVarFp.adminUserDataGetUserGroups(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [tempPw] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserDataResetPassword(id?: string, tempPw?: string, options?: any): AxiosPromise<File> {
            return localVarFp.adminUserDataResetPassword(id, tempPw, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserDataUpdateUserCompanies(requestBody: Array<string>, id?: string, options?: any): AxiosPromise<File> {
            return localVarFp.adminUserDataUpdateUserCompanies(requestBody, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserContact} userContact 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserDataUpdateUserContactInfo(userContact: UserContact, id?: string, options?: any): AxiosPromise<File> {
            return localVarFp.adminUserDataUpdateUserContactInfo(userContact, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [userId] 
         * @param {string} [groupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserDataUpdateUserGroup(userId?: string, groupId?: string, options?: any): AxiosPromise<File> {
            return localVarFp.adminUserDataUpdateUserGroup(userId, groupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserDataUsers(options?: any): AxiosPromise<Array<UserOutput>> {
            return localVarFp.adminUserDataUsers(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminUserDataApi - interface
 * @export
 * @interface AdminUserDataApi
 */
export interface AdminUserDataApiInterface {
    /**
     * 
     * @param {UserInput} userInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserDataApiInterface
     */
    adminUserDataCreateUser(userInput: UserInput, options?: AxiosRequestConfig): AxiosPromise<File>;

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserDataApiInterface
     */
    adminUserDataDeleteUser(id?: string, options?: AxiosRequestConfig): AxiosPromise<File>;

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserDataApiInterface
     */
    adminUserDataGetUserById(id?: string, options?: AxiosRequestConfig): AxiosPromise<UserOutput>;

    /**
     * 
     * @param {string} [username] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserDataApiInterface
     */
    adminUserDataGetUserByName(username?: string, options?: AxiosRequestConfig): AxiosPromise<UserOutput>;

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserDataApiInterface
     */
    adminUserDataGetUserGroups(id?: string, options?: AxiosRequestConfig): AxiosPromise<Array<GroupData>>;

    /**
     * 
     * @param {string} [id] 
     * @param {string} [tempPw] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserDataApiInterface
     */
    adminUserDataResetPassword(id?: string, tempPw?: string, options?: AxiosRequestConfig): AxiosPromise<File>;

    /**
     * 
     * @param {Array<string>} requestBody 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserDataApiInterface
     */
    adminUserDataUpdateUserCompanies(requestBody: Array<string>, id?: string, options?: AxiosRequestConfig): AxiosPromise<File>;

    /**
     * 
     * @param {UserContact} userContact 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserDataApiInterface
     */
    adminUserDataUpdateUserContactInfo(userContact: UserContact, id?: string, options?: AxiosRequestConfig): AxiosPromise<File>;

    /**
     * 
     * @param {string} [userId] 
     * @param {string} [groupId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserDataApiInterface
     */
    adminUserDataUpdateUserGroup(userId?: string, groupId?: string, options?: AxiosRequestConfig): AxiosPromise<File>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserDataApiInterface
     */
    adminUserDataUsers(options?: AxiosRequestConfig): AxiosPromise<Array<UserOutput>>;

}

/**
 * AdminUserDataApi - object-oriented interface
 * @export
 * @class AdminUserDataApi
 * @extends {BaseAPI}
 */
export class AdminUserDataApi extends BaseAPI implements AdminUserDataApiInterface {
    /**
     * 
     * @param {UserInput} userInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserDataApi
     */
    public adminUserDataCreateUser(userInput: UserInput, options?: AxiosRequestConfig) {
        return AdminUserDataApiFp(this.configuration).adminUserDataCreateUser(userInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserDataApi
     */
    public adminUserDataDeleteUser(id?: string, options?: AxiosRequestConfig) {
        return AdminUserDataApiFp(this.configuration).adminUserDataDeleteUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserDataApi
     */
    public adminUserDataGetUserById(id?: string, options?: AxiosRequestConfig) {
        return AdminUserDataApiFp(this.configuration).adminUserDataGetUserById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [username] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserDataApi
     */
    public adminUserDataGetUserByName(username?: string, options?: AxiosRequestConfig) {
        return AdminUserDataApiFp(this.configuration).adminUserDataGetUserByName(username, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserDataApi
     */
    public adminUserDataGetUserGroups(id?: string, options?: AxiosRequestConfig) {
        return AdminUserDataApiFp(this.configuration).adminUserDataGetUserGroups(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {string} [tempPw] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserDataApi
     */
    public adminUserDataResetPassword(id?: string, tempPw?: string, options?: AxiosRequestConfig) {
        return AdminUserDataApiFp(this.configuration).adminUserDataResetPassword(id, tempPw, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} requestBody 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserDataApi
     */
    public adminUserDataUpdateUserCompanies(requestBody: Array<string>, id?: string, options?: AxiosRequestConfig) {
        return AdminUserDataApiFp(this.configuration).adminUserDataUpdateUserCompanies(requestBody, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserContact} userContact 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserDataApi
     */
    public adminUserDataUpdateUserContactInfo(userContact: UserContact, id?: string, options?: AxiosRequestConfig) {
        return AdminUserDataApiFp(this.configuration).adminUserDataUpdateUserContactInfo(userContact, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [userId] 
     * @param {string} [groupId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserDataApi
     */
    public adminUserDataUpdateUserGroup(userId?: string, groupId?: string, options?: AxiosRequestConfig) {
        return AdminUserDataApiFp(this.configuration).adminUserDataUpdateUserGroup(userId, groupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserDataApi
     */
    public adminUserDataUsers(options?: AxiosRequestConfig) {
        return AdminUserDataApiFp(this.configuration).adminUserDataUsers(options).then((request) => request(this.axios, this.basePath));
    }
}
